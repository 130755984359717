"use client";
import { logger } from "@/lib/logging/logger";

import { ClientUser } from "@/types";
import React, { useState } from "react";
import { getUserSubscriptionPlan } from "@/lib/server/pricing/stripe";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Switch } from "@/components/ui/switch";
import { PlatinumPlanCard } from "./PlatinumPriceCard";
import { DiamondPlanCard } from "./DiamondPlanCard";
import { GoldPlanCard } from "./GoldPlanCard";
import { IoHelp } from "react-icons/io5";
import { PossiblePlanKeys } from "@/lib/shared/pricing/utils";
import { usePricingItemsStore } from "../../stores/pricingItemsStore";
import clsx from "clsx";

type PricingTableType = {
  user: ClientUser | null;
  subscriptionPlan: Awaited<ReturnType<typeof getUserSubscriptionPlan>>;
  planSubscribedTo: PossiblePlanKeys;
  hideHeaders?: boolean;
};

export default function PricingTable({
  user,
  subscriptionPlan,
  planSubscribedTo,
  hideHeaders,
}: PricingTableType) {
  const { pricingItems } = usePricingItemsStore();
  const baseWrapperForPlanBannerStyle =
    "absolute -top-5 left-0 right-0 mx-auto w-32 rounded-full bg-gradient-to-r";

  const [isMonthly, setIsMonthly] = useState(
    ["goldPlanYearly", "diamondPlanYearly", "platinumPlanYearly"].includes(
      planSubscribedTo,
    )
      ? false
      : true,
  );
  logger.info("isMonthly", isMonthly);
  const renderToggle = () => {
    return (
      <div className="flex justify-center items-center w-full gap-2">
        Monthly{" "}
        <Switch
          checked={!isMonthly}
          onCheckedChange={() => setIsMonthly(!isMonthly)}
        />{" "}
        Yearly
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <div className="flex flex-col text-start text-slate-700 text-sm mt-6 px-8">
        <div className="flex w-full">
          <IoHelp width={20} className="w-5 h-5 text-slate-700 me-1" />
          <div>
            Yearly subscriptions are billed in full amount at the start. They
            cannot be upgraded or downgraded
          </div>
        </div>
        <div className="flex w-full">
          <IoHelp width={20} className="w-5 h-5 text-slate-700 me-1" />
          <div>
            If you upgrade a monthly subscription, a new subscription will start
            and the old one's credits will be added onto your new balance.
            Credits expire at the end of the month.
          </div>
        </div>
      </div>
    );
  };

  const billing = isMonthly ? "monthly" : "yearly";

  return (
    <>
      <div
        className={clsx(
          "mb-2 text-center max-w-7xl mx-auto",
          !hideHeaders && "mt-12",
        )}
      >
        {!hideHeaders && (
          <div className="mx-auto mb-2 sm:max-w-lg">
            <h1 className="text-6xl font-bold sm:text-7xl">Pricing</h1>
            <p className="mt-5 text-gray-600 sm:text-lg">
              Whether you&apos;re just trying out our service or need more,
              we&apos;ve got you covered.
            </p>
          </div>
        )}
        {renderToggle()}
        {renderDetails()}
        <div className="pt-12 px-8 lg:px-0 grid grid-cols-1 gap-10 xl:grid-cols-3 lg:grid-cols-2">
          <TooltipProvider>
            <GoldPlanCard
              baseWrapperForPlanBannerStyle={baseWrapperForPlanBannerStyle}
              activeSubscriptionPlan={subscriptionPlan}
              billing={billing}
              user={!!user}
              // pricingStore={pricingStore}
              pricingItems={pricingItems}
            />
            <PlatinumPlanCard
              baseWrapperForPlanBannerStyle={baseWrapperForPlanBannerStyle}
              activeSubscriptionPlan={subscriptionPlan}
              billing={billing}
              user={!!user}
              // pricingStore={pricingStore}
              pricingItems={pricingItems}
            />
            <DiamondPlanCard
              baseWrapperForPlanBannerStyle={baseWrapperForPlanBannerStyle}
              activeSubscriptionPlan={subscriptionPlan}
              billing={billing}
              user={!!user}
              // pricingStore={pricingStore}
              pricingItems={pricingItems}
            />
          </TooltipProvider>
        </div>
      </div>
    </>
  );
}
