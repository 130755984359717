"use client";
import React from "react";
import { Container } from "@/components/landing/Container";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronUpIcon } from "lucide-react";

export const Faq = () => {
  return (
    <Container className="!p-0">
      <div className="w-full max-w-2xl p-2 mx-auto rounded-2xl">
        {faqdata.map((item, index) => (
          <div key={item.question} className="mb-5">
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="flex items-center justify-between w-full px-4 py-4 text-lg text-left text-gray-800 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-100 focus-visible:ring-opacity-75 dark:bg-trueGray-800 dark:text-gray-200">
                    <span>{item.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-indigo-500`}
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="px-4 pt-4 pb-2 text-gray-500 dark:text-gray-300">
                    {item.answer}
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </Container>
  );
};

const faqdata = [
  {
    question: "Is xchess.ai suitable for beginners?",
    answer:
      "Yes! Our platform is designed to help players of all skill levels, from beginners to advanced. The AI adapts its analysis to your playing strength.",
  },
  {
    question: "Can I use xchess.ai on my mobile device?",
    answer:
      "Absolutely! xchess.ai is fully responsive and works on desktop, tablet, and mobile devices.",
  },
  {
    question: "How accurate is the OCR for converting scoresheets?",
    answer:
      "Our OCR technology boasts a high accuracy rate. However, you can always review and edit the results to ensure 100% accuracy.",
  },
  {
    question: "Is my data secure on xchess.ai?",
    answer:
      "We take data security seriously. All your games and personal information are encrypted and stored securely. We never share your data without your explicit permission.",
  },
];
