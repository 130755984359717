"use client";
import { Card, CardContent } from "@/components/ui/card";
import { Camera, Scan } from "lucide-react";

import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
  ReactNode,
} from "react";

interface CarouselContextType {
  currentIndex: number;
  next: () => void;
  previous: () => void;
  setItemCount: (count: number) => void;
}

const CarouselContext = createContext<CarouselContextType | undefined>(
  undefined,
);

interface CarouselProps {
  children: ReactNode;
  className?: string;
  autoPlay?: boolean;
  autoPlayInterval?: number;
}

export const Carousel: React.FC<CarouselProps> = ({
  children,
  className = "",
  autoPlay = false,
  autoPlayInterval = 5000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemCount, setItemCount] = useState(0);

  const next = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % itemCount);
  }, [itemCount]);

  const previous = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + itemCount) % itemCount);
  }, [itemCount]);

  const setItemCountCallback = useCallback((count: number) => {
    setItemCount(count);
  }, []);

  useEffect(() => {
    if (autoPlay) {
      const interval = setInterval(next, autoPlayInterval);
      return () => clearInterval(interval);
    }
  }, [autoPlay, autoPlayInterval, next]);

  return (
    <CarouselContext.Provider
      value={{
        currentIndex,
        next,
        previous,
        setItemCount: setItemCountCallback,
      }}
    >
      <div className={`relative flex ${className}`}>{children}</div>
    </CarouselContext.Provider>
  );
};

interface CarouselContentProps {
  children: ReactNode;
}

const CarouselContent: React.FC<CarouselContentProps> = ({ children }) => {
  const context = useContext(CarouselContext);
  if (!context)
    throw new Error("CarouselContent must be used within a Carousel");
  const { currentIndex, setItemCount } = context;

  useEffect(() => {
    setItemCount(React.Children.count(children));
  }, [children, setItemCount]);

  return (
    <div className="overflow-hidden w-full h-ful">
      <div
        className="flex transition-transform duration-300 ease-in-out h-full"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {children}
      </div>
    </div>
  );
};

interface CarouselItemProps {
  children: ReactNode;
}

const CarouselItem: React.FC<CarouselItemProps> = ({ children }) => {
  return <div className="flex-shrink-0 w-full h-full">{children}</div>;
};

interface CarouselControlProps {
  className?: string;
}

const CarouselPrevious: React.FC<CarouselControlProps> = ({
  className = "",
}) => {
  const context = useContext(CarouselContext);
  if (!context)
    throw new Error("CarouselPrevious must be used within a Carousel");
  const { previous } = context;

  return (
    <button
      onClick={previous}
      className={`absolute left-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md ${className}`}
      aria-label="Previous slide"
    >
      ← {/* You can replace this with an icon */}
    </button>
  );
};

const CarouselNext: React.FC<CarouselControlProps> = ({ className = "" }) => {
  const context = useContext(CarouselContext);
  if (!context) throw new Error("CarouselNext must be used within a Carousel");
  const { next } = context;

  return (
    <button
      onClick={next}
      className={`absolute right-2 top-1/2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-md ${className}`}
      aria-label="Next slide"
    >
      → {/* You can replace this with an icon */}
    </button>
  );
};

const ChessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="w-24 h-24 text-purple-500 mb-4"
  >
    <path d="M8 16l-1.447.724A1 1 0 0 0 7 18v2a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2a1 1 0 0 0-.553-.894L15 16"></path>
    <path d="M12 4v3"></path>
    <path d="M9.5 8h5"></path>
    <path d="M8 12h8"></path>
    <path d="M7 16h10"></path>
  </svg>
);

const ChessOCRProcessCarousel = () => {
  const steps = [
    {
      title: "Capture Scoresheet",
      description:
        "Use your phone camera to capture the image of a chess scoresheet or multiple scoresheets. PDFs are also supported.",
      icon: <Camera className="w-24 h-24 text-blue-500 mb-6" />,
      animation: (
        <div className="relative w-36 h-32 sm:w-96 sm:h-72 mx-auto">
          <div className="absolute inset-0 bg-gray-200 rounded-lg animate-pulse"></div>
          <div className="absolute inset-4 bg-white rounded-lg shadow-inner flex items-center justify-center">
            <div className="w-30 h-36 sm:w-48 sm:h-64 bg-gray-300 rounded"></div>
          </div>
          <div className="absolute -bottom-8 -right-8 w-24 h-36 sm:w-40 sm:h-64 bg-blue-500 rounded-lg shadow-lg flex items-center justify-center">
            <Camera className="w-20 h-20 text-white" />
          </div>
        </div>
      ),
    },
    {
      title: "AI OCR Analysis",
      description:
        "Our AI OCR and advanced chess AI algorithms analyze the image and extract the chess moves.",
      icon: <Scan className="w-24 h-24 text-green-500 mb-6" />,
      animation: (
        <div className="relative w-48 h-36 sm:w-96 sm:h-72 mx-auto">
          <div className="absolute inset-0 bg-gray-200 rounded-lg overflow-hidden">
            <div className="absolute inset-0 bg-green-200 animate-scan"></div>
          </div>
          <div className="absolute inset-4 bg-white rounded-lg shadow-inner flex items-center justify-center">
            <div className="w-64 h-56 bg-gray-100 rounded p-4">
              <div className="w-full h-4 bg-gray-300 rounded mb-4"></div>
              <div className="w-full h-4 bg-gray-300 rounded mb-4"></div>
              <div className="w-3/4 h-4 bg-gray-300 rounded"></div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Display Chessboard",
      description:
        "A chessboard is displayed with the PGN extracted from the scoresheet. Analyze, Review, Improve your game.",
      icon: <ChessIcon />,
      animation: (
        <div className="relative w-48 h-48 sm:w-72 sm:h-72 mx-auto">
          <div className="absolute inset-0 bg-amber-100 rounded-lg"></div>
          <div className="absolute inset-2 grid grid-cols-8 grid-rows-8">
            {[...Array(64)].map((_, i) => (
              <div
                key={i}
                className={`${
                  (i + Math.floor(i / 8)) % 2 === 0
                    ? "bg-amber-700"
                    : "bg-amber-200"
                } ${i < 16 || i >= 48 ? "animate-piece-appear" : ""}`}
              ></div>
            ))}
          </div>
          <div className="absolute -top-2 -right-2 w-20 h-20 sm:w-36 sm:h-36 bg-gray-50 rounded-lg shadow-lg flex items-center justify-center">
            <div className="text-xs sm:text-lg p-2 text-gray-600">
              1. e4 e5 2. Nf3 Nc6 3. Bb5
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full max-w-3xl h-[600px] flex mx-auto p-4 shadow-gray-100 shadow-xl rounded-xl">
      <Carousel autoPlay className="w-full h-full">
        <CarouselContent>
          {steps.map((step, index) => (
            <CarouselItem key={index}>
              <div className="p-4 h-full">
                <div className="h-full flex flex-col items-center text-center">
                  {step.icon}
                  <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                  <p className="text-gray-600 mb-4">{step.description}</p>
                  <div className="w-full my-auto">{step.animation}</div>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default ChessOCRProcessCarousel;
